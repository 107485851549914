@import '../App.scss';

#tcModal {
    max-width: 70%;
    margin: auto;
    max-height: 80%;
    overflow-y: auto;
}

.tcContent {
    width: 90%;
    margin: auto;
    grid-row: 2;
    grid-column: 1/12;
    text-align: justify;
}

#btnCloseTop {
    color: indianred;
    grid-column: 1;
    grid-row: 1;
}

#btnCloseBtm {
    color: blue;
    grid-column: 1;
    grid-row: 3;
}

/***
It is not possible to apply classes or IDs to the body of the Contentful content, so it is 
    necessary to target 
***/
h1 {
    color: $blu-dark;
    font-size: 1.5rem;
    font-weight: lighter;
    text-align: center;
    margin-top: 0px;
    grid-column: 2 / 12;
    grid-row: 1;
}

h2 {
    color: $orn-bright;
    font-size: 1.2rem;
    font-weight: lighter;
}

h4 {
    color: $blu-dark;
    font-size: 1.0rem;
    font-weight: lighter;
}

p {
    color: $gry-dark;
    font-size: .9rem;
    font-weight: lighter;
}

li {
    color: $gry-dark;
    font-size: .9rem;
    font-weight: lighter;
}