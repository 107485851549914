@import '../App.scss';

.wrapArticles {
    max-width: 1000px;
    margin: auto;
    border-right: 1px solid grey;
    border-left: 1px solid grey;
}

.blogWrap {
    
}

.blogTitle {
    color: $gry-dark;
    background-color:$orn-pale;
    padding: 10px;
    max-width: 90%;
    margin: auto;
  }

.blogSubtitle {
    color: $orn-bright;
    font-size: smaller;
    font-style: italic;
}

.wrapExcp {
    display: flex;
    cursor: pointer;
}

.blogImgLeft {
    margin-bottom: 10px;
    margin-left: 40px;
    border: 1px solid #fff;
    box-shadow: 5px 5px 5px #ccc;
}

.blogExcp {
    margin: 0px 10px 0px 10px;
    color: $blu-dark;
    font-size: smaller;
    text-align: left;
}

.blogIntro {
    text-align: justify;
}

.introBody {
    margin: 15px 40px 0px 10px;
    color: $gry-dark;
    font-size: smaller;
}

@media screen and (max-width: 768px) {
    .wrapExcp {
        flex-direction:column;
    }

    .blogImgLeft {
        width: 120px;
        margin-left: 10px;
    }

   .readMore {
    display: inline-block;    
    font-size: larger;
    margin-bottom: 10px;
    font-weight: bolder;
    }
}