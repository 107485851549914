.App {
  text-align: center;
  display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* ===================== NEW ======================== */
$orn-bright: #e37b34;
$orn-dull: #d09842;
$orn-flat:  #d6c4a9;
$orn-pale:  #f1ece6;
$blu-light: #91add4;
$blu-medium: #6daac1;
$blu-dark: #6086ba; 
$gry-dark: #8E8A8A;
$gry-backlight: #f0f0f0;
$grn-ghost: #d9f5ec;

textarea {
  resize: none;
}
/* ====================================================================================== */@at-root


/* --------------------- header --------------------- */
.main-menu {
list-style-type: none;
display: flex;
justify-content: center;

}

/* --------------------- sign up form --------------------- */
#sign-up-form {
  display: flex;
  flex-direction: column;
  width: 35%;
  margin: auto;
}

#signUpBtn {
  margin-top: 10px;
}

#lblSignUpTC {
  display: inline;
}

/*
------------------------------ Page Layout -------------------------------
*/

.pageWrap {
  margin-top: 175px;
}



/* --------------------- Excerpts Page --------------------- */

.exp-wrap:hover {
  cursor:pointer;
}

/*-- grid layout for excerpt fields -- */

/* ---------------------- React Player --------------------- */
.player-wrapper {
  position: relative;
  // padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: relative;
  top: 0;
  left: 50%; 
  transform: translateX(-50%);
  max-width: 100%;
}

/* ------------------------ Modals ------------------------- */
.modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modalContent {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px;
  width: max-content;
  /*-- css grid settings -- */
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 5px;
  grid-auto-rows: min-content;
}



/*-- grid layout for source Modal fields -- */

/* -- adjust modal width -- */
#srcModal {
  width: 1280px;
  background: lightsalmon;
}

#srcReadModal {
  width: 1280px;
  background: #D9F5EC;
}

/* -- form header -- */
.modSrcHead {
  grid-column: 1 / 12;
  grid-row: 1;
  text-align: center;
}

/* -- title -- */
#lblTitle {
  grid-column: 1 ;
  grid-row: 2;
}
#txtTitle {
  grid-column: 2  / 12;
  grid-row: 2;
}

/* -- author -- */
#lblAuth {
  grid-column: 1;
  grid-row: 3;
}
#txtAuthor {
  grid-column: 2 / 5;
  grid-row: 3;
}

/* -- type -- */
#lblType {
  grid-column: 1;
  grid-row: 4;
}
#txtType {
  grid-column: 2;
  grid-row: 4;
}

/* -- publisher -- */
#lblPub {
  grid-column: 3;
  grid-row: 4;
}
#txtPublisher {
  grid-column: 4 /  10;
  grid-row: 4;
}

/* -- discipline -- */
#lblDisc {
  grid-column: 10;
  grid-row: 4;
}
#txtDiscipline {
  grid-column: 11;
  grid-row: 4;
}

/* -- url -- */
#lblUrl {
  grid-column: 1;
  grid-row: 7;
}
#txtUrl {
  grid-column: 2 / 12;
  grid-row: 7;
}

/* -- notes -- */
#lblNotes {
  grid-column: 1;
  grid-row: 8;
}
#txaNotes {
  grid-column: 2 / 12;
  grid-row: 8 / 10;
}

/* -- cite plain -- */
#lblPlain {
  grid-column: 1;
  grid-row: 11;
}
#txtCitePlain {
  grid-column: 2 / 11;
  grid-row: 11;
}

/* -- cite latex -- */
#lblLatex {
  grid-column: 1;
  grid-row: 12;
}
#txtCiteLatex {
  grid-column: 2 / 11;
  grid-row: 12;
}

/* -- buttons -- */
#wrap_btnSrcAddCancel {
  grid-column: 5;
  grid-row: 13;
}

#wrap_btnSrcAddConfirm {
  grid-column: 6;
  grid-row: 13;
}

/* ------------------------ Excerpts Page ------------------------- */
.btnStd {
    margin: 5px;
    padding: 2px;
    width: 150px;
}

/* ------------------------- Delete Excerpts Modal ---------------------------- */
#wrap_btnDelExcp {
  grid-column: 3;
  grid-row: 3;
}

#wrap_btnDelExcpCancel {
  grid-column: 5;
  grid-row: 3;
}
/* ------------------------ Add / Edit Excerpts Modal ------------------------- */
#excpModal {
  width: 980px;
  background: lightsalmon;
}

#excpReadModal {  
  width: 1280px;
  background: #D9F5EC;
  margin-top: 150px; // do the same for all modals

}

#excpModalHead {
  grid-column: 1 / 12;
  grid-row: 1;
  text-align:center;
}

/* -- title -- */
#lblTitle {
  width:100%;
  grid-column: 1;
  grid-row: 2;
} 
#txtTitle {
  width:100%;
  grid-column: 2 / 12;
  grid-row: 2;
}

/* -- body -- */
#lblBody {
  width:100%;
  grid-column: 1;
  grid-row: 3;
}
#txaBody {
  width:100%;
  grid-column: 2 / 12;
  grid-row: 3;
}

/* -- pages -- */
#lblPages {
  width:100%;
  grid-column: 1;
  grid-row: 4;
}
#txtPages {
  width:100%;
  grid-column: 2;
  grid-row: 4;
}

/* -- use -- */
#lblUse {
  width:100%;
  grid-column: 1;
  grid-row: 5;
}
#txaUse {
  width:100%;
  grid-column: 2 / 12;
  grid-row: 5;
}

/* -- notes -- */
#lblNotes {
  width:100%;
  grid-column: 1;
  grid-row: 6;
}
#txaNotes {
  width:100%;
  grid-column: 2 / 12;
  grid-row: 6;
}

/* -- source -- */
.selExcpSource {
  width: 300px;
  svg {
    height: 1rem;
  }

}

/* -- tags -- */
.selExcpTags {
  width: 500px;
  svg {
    height: 1rem;
  }
}

#sourceSelect {
  text-align:left;
  grid-column: 2 / 6;
  grid-row: 7;
}

#tagSelect {
  text-align:left;
  grid-column: 6 / 11;
  grid-row: 7;
}

/* -- buttons -- */
#wrap_btnExpAddTags {
  text-align:left;
  grid-column: 6 / 7;
  grid-row: 8;
}

#wrap_btnExpAddCancel {
  grid-column: 9;
  grid-row: 8;
}

#wrap_btnExpAddConfirm {
  grid-column: 10;
  grid-row: 8;
}

/*-- grid layout for tag Modal fields -- */

/* -- adjust modal width -- */
#tagModal {
  width: 300px;
}

#modTagTitle {
  grid-column: 1 / 12;
  grid-row: 1;
  text-align: center;
  margin: 5px;
}

#lblTagName {
  grid-column: 1 / 5;
  grid-row: 2;
}

#txtTagName {
  grid-column: 6 / 12;
  grid-row: 2;
}

#wrap_btnAddTag {
  grid-column: 1 / 5;
  grid-row: 3;
}

#wrap_btnClose {
  grid-column: 7 / 12;
  grid-row: 3;
}

/* ------------------------------------- Manage Tags Modal ------------------------- */
#wrap_btnTagEdit {
    grid-column: 1 / 2;
    grid-row: 3;
}

#wrap_btnTagDelete {
    grid-column: 2 / 4;
    grid-row: 3;
}

#wrap_btnTagCancel {
    grid-column: 5 / 7;
    grid-row: 3;
}

#wrap_btnTagConfirm {
    grid-column: 7 / 9;
    grid-row: 3;
}

#wrap_btnAddTag {
  grid-column: 3 / 6;
  grid-row: 8;
}

#wrap_btnClose {
  grid-column: 6/ 8;
  grid-row: 8;
}

/* ------------------------------------- Show Video Modal ------------------------- */




@media screen and (max-width: 1024px) {
  #btnVideoClose {
    transform: translateY(-30px);
  }
}

@media screen and (max-width: 768px) {
  #btnVideoClose {
    transform: translateY(3px);
  }
}

@media screen and (max-width: 520px) {
  #btnVideoClose {
    transform: translateY(-40px);
  }
}