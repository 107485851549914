.videoWrap {
    align-content: center;
    width: 90%;
    margin: auto;
}

.videoHead {
    margin: auto;
}

.videoRow {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    // min-width: 420px;
}

.sectionImage {
    max-width: 100%;
    margin-top: 30px;
}

.videoBlock {
    width: 300px;;
    text-align: center;
}

.videoReady {
    cursor: pointer;
}

.videoThumbnail {
    width: 100%;
}