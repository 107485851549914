@import '../App.scss';

#videoModal {
    position: relative;
    top: 15%;
    // transform: translateY(-50%);
    background-color: ivory;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 10px;
    width: 50%;
    height: max-content;
    margin-top: 100px;

    @media screen and (max-width: 768px) {
        margin-top: 70px;
        top: 15%;
        width: 90%;
    }

    @media screen and (max-width: 576px) {
        margin-top: 70px;
        top: 15%;
        width: 90%;
    }


}

