#delSrcModal {
    width: 500px;
    height:auto;
}

#modSrcDelTitle {
    color: red;
    grid-column: 1 / 12;
    grid-row: 1;
    text-align: center;
}

#modSrcDelBody {
    color: red;
    grid-column: 1 / 12;
    grid-row: 2;
    text-align: center;
}

#wrap_btnDelSrcConfirm {
    grid-column: 1 / 3;
    grid-row: 3;
}

#wrap_btnDelSrcCancel {
    grid-column: 3 / 5;
    grid-row: 3;
}



.selExcpTags {
    grid-column: 1 / 3;
    grid-row: 2;
}

#tagSelected {
    grid-column: 3 / 12;
    grid-row: 2;
}

#lblTagName {
    grid-column: 1 / 2;
    grid-row: 7;
}

#txtTagName {
    grid-column: 1 / 2;
    grid-row: 8;
}

/* wrapper for Edit and Delete buttons to create a sub-grid */
/*#wrapED {
    grid-column: 1 / 3;
    grid-row: 3;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}*/