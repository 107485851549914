
#modPasswModal {
    width: 100px;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 10px;
    width: max-content;
    /*-- css grid settings -- */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    grid-auto-rows: min-content;
}

#modPasswTitle {
    grid-column: 1 / 3;
    grid-row: 1;
    color: blue
}

#userNameLbl {
    grid-column: 1 / 2;
    grid-row: 2;
}

#userNameInput {
    grid-column: 2 / 3;
    grid-row: 2;
}

#submitBtn {
    grid-column: 2 / 3;
    grid-row: 3;
}

#closeBtn {
    grid-column: 1 / 2;
    grid-row: 3;
}

#emailSentMsg {
    grid-column: 1 / 3;
    grid-row: 4;
    text-align: center;
    color: red;
}