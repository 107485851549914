@import '../App.scss';

.navSel {
    color: red;
    font-size:  1.2rem;
  }

.navUnsel {
    color: $blu-dark;
    font-size:  1.2rem;
    font-weight: normal;
    display: inline-block;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    display: inline;
    &:hover {
        color: $orn-bright;
    }
}

li {
    font-weight: normal;
}

@media screen and (max-width: 450px) {
    .navsel, .navUnsel {
        font-size: 1rem;
    }
}