/*
This file only contains the very latest css elements. THe bulk of the css fot
    modSrcAdd is still lodged in app.css.
*/

#errMsg {
    grid-column: 7 / 12;
    grid-row: 13;
    margin: 0;
    color: red;
    text-align: left;
  }