
.art-page-head {
    font-size: 130%;
    color: lightslategrey;
}

.art-sub-head {
    color: green;
    font-style: italic;
}

.exp-page-head {
    font-size: 150%;
    color: blue;
}

.exp-sub-head {
    color: green;
    font-style: italic;
}