@import '../App.scss';

.sidebarRight {
  border: 1px dotted forestgreen;
  display: flex;
  width: 85%;
  margin: auto;
}

.mainBar {
    // border: 1px dotted blue;
    margin: 5px;
    width: 80%;
    height: 700px;
    overflow-y: auto;
}

.sideBar {
    // border: 1px dotted rebeccapurple;
    margin: 5px;
    width: 20%;
}

.excpWrap {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 5px;
    grid-auto-rows: minmax(10px, auto);
    border-bottom: 1px solid lightgray;
    margin-top: 15px;
    margin-bottom: 15px;
}

.excpWrap:hover {
    cursor: pointer;
}

#pageTitle {
    font-size: 1.5em;
    text-align: center;
    color: $blu-medium;
}

#title {
    width:100%;
    grid-column: 1 / 12;
    grid-row: 1;
    text-align:center;
    font-weight: 400; 
    font-size: x-large;
    margin: 5px;
    color: $blu-dark;
}

.titles {
    color: $orn-bright;
}

#body {
    color: $orn-dull;
    grid-column: 1 / 12;
    grid-row: 2;
    text-align:left;
    margin: 2px;
}

#source {
    grid-column: 1 / 10;
    grid-row: 3;
    text-align:left;
    margin: 2px;
}

#pages {
    grid-column: 9 / 12;
    grid-row: 3;
    text-align:left;
    margin: 2px;
}

#use {
    grid-column: 1 / 12;
    grid-row: 4;
    text-align:left;
    margin: 2px;
    color: $gry-dark;
}

#notes {
    grid-column: 1 / 12;
    grid-row: 6;
    text-align:left;
    margin: 2px;
    color: $blu-medium;
}

#tags {
    grid-column: 1 / 12;
    grid-row: 7;
    text-align:left;
    margin: 2px;
    margin-bottom: 10px;
}

.rcmTag {
    margin: 5px;
    padding: 0px 3px 0px 3px;
    background-color: $grn-ghost;
    border: 1px solid $blu-medium;
    border-radius: 10px;
    margin-top: 15px;
    font-size: smaller;
}

.expSelected {
    background: $gry-backlight;
}

.cboSrcSelect {
    width: 200px;
    margin: auto;
    svg {
        height: 1rem;
      }
    
}

.cboTagSelect {
    width: 200px;
    margin: auto;
    svg {
        height: 1rem;
      }
    
}

/* -------------------- SIDEBAR --------------- */
.sidebarHeads {
    // font-weight: lighter;
    font-size: smaller;
    color: $blu-medium;
    margin-bottom: 5px;
}