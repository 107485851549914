.loginWrap, .signupWrap {
    display: flex;
    flex-direction: column;
    min-height: 65vh;
    background-image: url("../images/pixabay-4912859_640.jpg");
    background-color: #cccccc;
}

.loginHead, .signupHead {
    width: 100%;
}

#logInForm a {
    color: blue;
    text-decoration: underline;    
    cursor: pointer;
    padding: 20px 0 20px 0;
}
.loginIntro, .signupIntro {
    padding: 0 20px 20px 20px;
    font-size: medium;
    margin: auto;
}


.loginPanel, .signupPanel {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-content: center;
    width: 50%;
    background-color: white;
    margin: auto;

    

}
#imageWrap {
    margin: auto;
}

.loginImage, .signupImage {
    max-width: 100%;
}

#logInForm, #signUpForm {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: auto;
}