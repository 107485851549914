@import '../App.scss';

.sidebarRight {
border: 1px dotted forestgreen;
display: flex;
width: 85%;
margin: auto;
}
  
.mainBar {
// border: 1px dotted blue;
margin: 5px;
width: 80%;
height: 700px;
overflow-y: auto;
}
  
.sideBar {
// border: 1px dotted rebeccapurple;
margin: 5px;
width: 20%;
}

#pageTitle {
font-size: 1.5em;
    text-align: center;
    color: $orn-bright;
}
 
.srcWrap {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 3px;
    grid-auto-rows: minmax(10px, auto);
    border-bottom: 1px solid lightgray;
    margin-top: 15px;
    margin-bottom: 15px;
}

.srcWrap:hover {
    cursor:pointer;
  }

#title {
    width:100%;
    grid-column: 1 / 12;
    grid-row: 1;
    text-align:center;
    font-weight: 400; 
    font-size: x-large;
    margin: 5px;
    color: $orn-bright;
}

.titles {
    color: $blu-dark;
    font-weight: bolder;
}

.lineItem {
    margin: 3px 0px 3px 0px;
}
  
// .srcField {
// margin: 0;
// padding: 0;
// text-align: left;
// }

#pageTitle {
grid-column: 1 / 10;
grid-row: 1;
font-size: 1.5em;
text-align: center;
color: $orn-bright;
}

#srcPgAuthor {
grid-column: 1 / 6;
grid-row: 2;
text-align: left;
}

#srcPgType {
grid-column: 6 / 8;
grid-row: 2;
text-align: left;
}

#srcPgPub {
grid-column: 8  / 12;
grid-row: 2;
text-align: left;
}

#srcPgDisc {
grid-column: 1 / 4;
grid-row: 3;
text-align: left;
}

#srcPgUrl {
grid-column: 4 /12;
grid-row: 3;
text-align: left;
}

#srcPgNotes {
grid-column: 1 / 12;
grid-row: 4;
text-align: left;
}

#srcPgPlain {
grid-column: 1 / 6;
grid-row: 5;
text-align: left;
}

#srcPgLatex {
grid-column: 6 / 12;
grid-row: 5;
margin-bottom: 20px;
text-align: left;
}

.srcSelected {
    background: $gry-backlight;
}

/* -------------------- SIDEBAR --------------------- */
.sidebarHeads {
    // font-weight: lighter;
    font-size: smaller;
    color: $orn-bright;
    margin-bottom: 5px;
}