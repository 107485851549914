
@import '../App.scss';

$header-height: 132px;
$footer-height: 70px;
// $body-height: calc(100vh - ($header-height + $footer-height));
$body-height: 110vh;

.sidebarRight {
    // border: 1px dotted forestgreen;
    display: flex;
    width: 85%;
    margin: auto;
}

.mainBar {
    margin: 5px;
    // height: $body-height;
    // min-width: 560px;
    // overflow-y: auto;
    overflow-x: hidden;
}

/* ----------------------- SIDEBAR ---------------- */
.sideBar {
    /* border: 3px dotted rebeccapurple; */
    margin: 5px;
    width: 20%;
    min-width: 140px;
  }

  .raHeadBlock {
    background-color: $orn-flat;
    min-height: 70px;
  }

  #raHead {
    color: white;
    padding: 10px 5px 10px 5px;
  }

  #raExcp {
    color: $blu-dark;
    font-size: smaller;
    
  }
  .articleStub {
    cursor: pointer;
  }

  @media only screen and (min-width: 320px) {
    .sideBar {
        display: none;
    }
    .mainBar {
        width: 100%;
        overflow-y: hidden;
        height: auto;
    }
  }

@media only screen and (min-width: 1024px) {
    .sideBar {
        display: block;
    }
    .mainBar {
        width: 80%;
        margin: 5px;
        // height: $body-height;
        min-width: 560px;
        // overflow-y: auto;
        overflow-x: hidden;
    }
}