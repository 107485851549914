@import '../App.scss';

#contactWrap {
    min-height: 65vh;
    // background-image: url(../images/contact-background-01.jpg);
}

#contactForm {
    display: flex;
    flex-direction: column;
    width: 35%;
    margin: auto;
    margin-top: 30px;
    
  }
  
#head {
    color: $orn-dull;
    background: white;
}

#subHead {
    color: $blu-dark;
    width: 50%;
    margin: auto;
    font-weight: lighter;
    background: white;
    padding: 10px 0px 10px 0px;
}

#msgTitle {
    color: red;
}

.goHome {
    color: red;
    cursor: pointer;
    &:hover {
        font-weight: bold;
        color: blue;
    }
}