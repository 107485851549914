@import '../App.scss';
.head {
    margin-top: 1px;
    margin-bottom: 50px;
    margin-top: 90px;
    font-weight: bolder;
    font-size: xx-large;
}
.subHead {
    color: $orn-bright;
    font-weight: 600;
    font-size: larger;
}

.intro {
    color: $gry-dark;
    font-size: 1.1rem;
}

#imgJumbo {
    width: 100%;
}

// new arrangement for block image to be SVGs
.headImgBlock {
    display: flex;
    justify-content:space-evenly;
}

.oneThird {
    ///border: 1px solid red;
    width: 33%;
    min-height: 40px;
}

.imgHeadBus {
    width: 55%;  // was 67%
    height: 100%;
    //border: 1px solid blue; 
}

.imgHeadTech {
    width: 55%;
    height: 100%;
    //border: 1px solid blue; 
}

.imgHeadSci {
    width: 55%;
    height: 100%;
    //border: 1px solid blue; 
}


// ---- end 

.aside1 {
    background: #f9f9f9;
    border-left: 20px solid $blu-light;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
    font-style: italic;
    color: $blu-dark;
    font-weight: 500;
    font-size: 1.1rem;
}

.signUpHead {
    color: $orn-bright;
    font-weight: 600;
}

.warning {
    color: red;
    margin-bottom: 50px;
}

.signUpBody {
    color: $gry-dark;
    font-size: 1.1rem;
}

.videoHead {
    color: $orn-bright;
    font-weight: 600;
}

.videoIntro {
    color: $gry-dark;
    font-size: 1.1rem;
}

.learnVideo {
    margin: auto;
}

/* -------- override ReactPlayer settings --------- */
.playerWrap {
    padding-top: 56.25%; // Percentage ratio for 16:9
    position: relative; // Set to relative
}

.player-wrapper {
    width: auto; // Reset width
    height: auto; // Reset height
  }
  .react-player {
   top: 0;
   left: 0;
   position: absolute;
  }
  

/* ------------- services 6-up block -------------- */
.rowWrap {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    // min-width: 420px;
}

.servicesHead {
    font-size: xx-large;
    font-weight: 600;
}
svg {
    height: 2em;
    width: 2em;
    transform: scale(1.15);
    color: $orn-bright;
}

.threePlyIconBlock {
    width: 280px;
    align-content: left;
}

.iconBlockHead {
    text-align: left;
    color: $blu-dark;
    font-weight: 600;
}

.iconBlockBody {
    margin-left: 25px;
    text-align: left;
    color: $gry-dark;
    font-size: 1.1rem;
}

/* -------------------- Ref Mgr Icon Ribbon -------------- */
.iconRibbonWrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}

.fourPlyIconBlock {
    width: 25%;
}

.ribbonIcon {
    transform: scale(3);
}