@import '../App.scss';

.footerWrap {
    margin: auto;
    text-align: center;
    margin-top: 20px;
    padding-top: 10px;
    background-color: lightgrey;
    width: 100%;
}

.footerFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#title {
    margin-bottom: 5px;
    margin-left: 10px;
    color: $orn-dull;
    font-size: small;
    margin-top: 4px;
}

#tagline {
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 5px;
    color: $blu-dark;
    font-size: small;
}

#user {
    text-align: center;
}

#footerMenu {
    text-align: left;
    font-size: smaller;
}
.footerLink {
    padding-left: 5px;
    list-style-type: none;
}

.footerAnchor {
    cursor: pointer;
    display: block;
}

@media screen and (max-width: 768px) {
    .footerFlex {
        flex-direction: column;
        text-align: center;
    }

    .footerLink {
        text-align: center;
    }
}