@import '../App.scss';

.mainMenu {

}

.headWrap {
    width:  100%;
    margin: auto;
    text-align: left;
    padding-top: 20px;
    position: fixed;
    z-index: 10;
    background-color: white;
}

.logoEtc {
    display: flex;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 5px;
    padding-left: 30px;
}

.preHead {
    text-align: center;
    margin-bottom:12px;
}

#title {
    margin-bottom: 5px;
    margin-left: 10px;
    color: $blu-dark;
}

#tagline {
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 5px;
    color: $gry-dark;
    font-size: 1.05em;
}

#user {
    text-align: center;
    color: $orn-bright;
    margin-top: 1px;
    margin-bottom: 1px;
}

@media screen and (max-width: 450px) {
    #tagline {
        font-size: 0.85rem;
        font-weight:bolder;
    }

    .headImg {
        height: 60px;
    }
}