#delExcpModal {
    width: 500px;
    height:auto;
}

#modExcpDelTitle {
    color: red;
    grid-column: 1 / 12;
    grid-row: 1;
    text-align: center;
}

#modExcpDelBody {
    color: red;
    grid-column: 1 / 12;
    grid-row: 2;
    text-align: center;
}

/* styles for buttons are in styles.css */
