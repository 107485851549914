@import '../App.scss';
.elmWrap {
    background-color: bisque;
}

.title {
    color: red;
}
.subHead {
    color: $orn-bright;
    font-weight: 600;
    font-size: larger;
}

.bodyText {
    color: $gry-dark;
    font-size: 1.1rem;
}

#btnVidSignUp {
    cursor: pointer;
}